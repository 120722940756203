import { useEffect, useState } from "react";
import "./IconIndicator.scss";
import { ReactComponent as GrowthSVG } from "./svg/growth_indicator.svg";

export const IconIndicator = ({
  orientation = "left",
  color = "green",
  id = "",
  animate = true,
  animateTrigger = "",
}: {
  orientation: "left" | "right" | "up" | "down";
  color?: "green" | "red" | "grey";
  id?: string;
  animate?: boolean;
  animateTrigger?: any;
}) => {
  const [animateClass, setAnimateClass] = useState("");

  useEffect(() => {
    if (animate && animateTrigger) {
      setAnimateClass("");
      const timeout = setTimeout(() => {
        setAnimateClass("icon-growth_indicator__animate");
      }, 10);

      return () => clearTimeout(timeout);
    }
  }, [orientation, animateTrigger, animate]);

  const classname = `icon-growth_indicator__${orientation} icon-growth_indicator__${color} ${animateClass}`;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classname}
      viewBox="0 0 576 512"
      width="12"
      height="10.5"
      fill="none"
    >
      <path d="M534.1 448H41.9C18.6 448 5.4 421.1 19 403.4L251.1 55.7c6.8-9.7 17.6-15.7 28.9-15.7s22.1 6 28.9 15.7L557 403.4c13.6 17.7.5 44.6-23 44.6z" />
    </svg>
  );
};
