import React, { useState, useEffect, useRef } from "react";
import debounce from "lodash-es/debounce";
import "./WheelPickerColumn.scss";

type Props = {
  templateToRender: Function;
  dataToRender: Array<any>;
  disabledIndexesFromSelection?: Array<number>;
  selectedItemKey: any;
  callbackOnItemInViewKeyChanged: CallableFunction;
  callbackItemKeyInViewIsDisabled?: CallableFunction;
  isOpen: boolean;
};

export const WheelPickerColumn = ({
  templateToRender,
  dataToRender,
  disabledIndexesFromSelection,
  callbackOnItemInViewKeyChanged,
  callbackItemKeyInViewIsDisabled,
  selectedItemKey,
  isOpen,
}: Props) => {
  const scroller = useRef<HTMLUListElement>(null);
  const itemsRenderHeight = 40;
  const [updating, setIsUpdating] = useState(false);

  const updatePosY = debounce((nearestItem) => {
    return setCurrentItemInView(nearestItem);
  }, 5);

  useEffect(() => {
    if (isOpen) {
      if (scroller.current && scroller.current.scrollTop !== selectedItemKey * itemsRenderHeight) {
        scrollToSelected(selectedItemKey, scroller);
      }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleScroll = (e: React.UIEvent<HTMLUListElement>) => {
    e.preventDefault();
    if (updating || !isOpen) {
      return;
    }
    const scrollYFromTop = e.currentTarget.scrollTop;
    const findNearestItem = Math.round(scrollYFromTop / itemsRenderHeight);

    // we can check if we are on a disabled option here
    if (disabledIndexesFromSelection && Array.isArray(disabledIndexesFromSelection)) {
      const isShowingADisabledOption = disabledIndexesFromSelection.filter((index) => index === findNearestItem);

      if (callbackItemKeyInViewIsDisabled) {
        if (callbackItemKeyInViewIsDisabled && isShowingADisabledOption?.length > 0) {
          callbackItemKeyInViewIsDisabled(true);
        } else {
          callbackItemKeyInViewIsDisabled(false);
        }
      }
    }

    updatePosY(findNearestItem);
  };

  const scrollToSelected = (y: number, container: any) => {
    setIsUpdating(true);
    container.current.scrollTo({ top: y * itemsRenderHeight, behavior: "instant" });
    setTimeout(() => setIsUpdating(false), 55);
  };

  const onItemInViewChanged = (newItemInViewKey: any) => {
    callbackOnItemInViewKeyChanged(newItemInViewKey);
  };

  const setCurrentItemInView = (nearestItem: any) => {
    if (dataToRender && nearestItem !== dataToRender[selectedItemKey]) {
      const newItemInViewKey = nearestItem;
      onItemInViewChanged(newItemInViewKey);
    }
  };

  const isOptionSelected = (index: number) => {
    return index === selectedItemKey;
  };

  const isOptionDisabled = (indexToCheck: number) => {
    if (disabledIndexesFromSelection) {
      return disabledIndexesFromSelection.includes(indexToCheck);
    }
    return false;
  };

  return (
    <ul className="WheelPickerColumn" onScroll={handleScroll} ref={scroller} role="listbox">
      {dataToRender.map((data: any, index) => {
        return templateToRender({ data, isSelected: isOptionSelected(index), isDisabled: isOptionDisabled(index) });
      })}
    </ul>
  );
};
