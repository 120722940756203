import { IconIndicator } from "../../icons/IconIndicator/IconIndicator";
import "./LeafTrendIndicator.scss";

export enum TrendEnum {
  HIGHER = "HIGHER",
  LOWER = "LOWER",
  EQUAL = "EQUAL",
}

export type Props = {
  trend: TrendEnum;
  number: number;
  preference?: TrendEnum;
};

const LeafTrendIndicator = (props: Props) => {
  const { trend, number, preference = TrendEnum.HIGHER } = props;

  const getColorClass = () => {
    if (preference === TrendEnum.LOWER) {
      if (trend === TrendEnum.LOWER) return "green";
      if (trend === TrendEnum.EQUAL) return "grey";
      return "red";
    }
    if (preference === TrendEnum.HIGHER) {
      if (trend === TrendEnum.HIGHER) return "green";
      if (trend === TrendEnum.EQUAL) return "grey";
      return "red";
    }
    if (preference === TrendEnum.EQUAL) {
      if (trend === TrendEnum.EQUAL) return "green";
      return "red";
    }
  };

  const getTrendIcon = () => {
    if (trend === TrendEnum.HIGHER)
      return <IconIndicator color={getColorClass()} orientation={"up"} animateTrigger={number > 0} />;
    if (trend === TrendEnum.LOWER)
      return <IconIndicator color={getColorClass()} orientation={"down"} animateTrigger={number > 0} />;
    if (trend === TrendEnum.EQUAL)
      return <IconIndicator color={getColorClass()} orientation={"left"} animateTrigger={number > 0} />;
  };

  return (
    <div className="leaf-trend-indicator">
      <span className={`leaf-trend-indicator__trend-icon leaf-trend-indicator__${getColorClass()}`}>
        {getTrendIcon()}
        <span className="leaf-trend-indicator__trend-number">{number} </span>
      </span>
    </div>
  );
};

export default LeafTrendIndicator;
