import { dispatchExportInProgressEvent } from "../../components/ServerSideExport/ServerSideExportCommon/ServerSideExportUtil/ServerSideExportEvents";
import { ExportDetails } from "../../services/ExportV2/exportV2API.types";
import { STATUS_IN_PROGRESS, STATUS_PENDING } from "../constants/export-constants";

// Dispatches ExportInProgress event, which is used to hide or show export InProgress icon
export const updateInProgressIndicator = (exportsList: ExportDetails[] = []) => {
  const inProgressStatus = [STATUS_PENDING, STATUS_IN_PROGRESS];
  const isInProgress = exportsList.some(({ ExportStatus }) => inProgressStatus.includes(ExportStatus));
  dispatchExportInProgressEvent(isInProgress);
};
