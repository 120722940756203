import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "..";
import { BronzeGroupTrackingApiResponse, BGTEligibilityResponse, BgtMiniProfileApi } from "./bronzeGroupTracking.types";

type BaseRequestParams = {
  aff: string;
  abo: string;
  period: string;
  targetAffAbo: string;
};

type BgtMiniProfileApiRequestParams = {
  aff: string;
  abo: string;
  bonusPeriod: string;
  countryCode?: string;
};

type BgtEligibilityApiRequestParams = {
  affAbos: string[];
  period: string;
  countryCode: string;
};

export const bronzeGroupTrackingAPI = createApi({
  reducerPath: "bronzeGroupTrackingAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getBronzeGroupTrackingApi: builder.query<BronzeGroupTrackingApiResponse, BaseRequestParams>({
      query: (params) => {
        const { abo, aff, period, targetAffAbo } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          TargetAffAbo: targetAffAbo,
        });

        return `/bronze-group-tracking/api/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getBgtMiniProfileApi: builder.query<BgtMiniProfileApi, BgtMiniProfileApiRequestParams>({
      query: (params) => {
        const { abo, aff, bonusPeriod, countryCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: bonusPeriod,
          IsoCountryCode: `${countryCode}`,
        });

        return `/bronze-group-tracking/api/mini-profile?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getBronzeGroupTrackingEligibilityApi: builder.query<BGTEligibilityResponse, BgtEligibilityApiRequestParams>({
      query: (params) => {
        const { affAbos, period, countryCode } = params;
        const queryParams = new URLSearchParams({
          AffAbos: `${affAbos}`,
          Period: period,
          IsoCountryCode: countryCode,
        });
        return `/bronze-group-tracking/api/eligibility?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const {
  useGetBronzeGroupTrackingApiQuery,
  useGetBgtMiniProfileApiQuery,
  useGetBronzeGroupTrackingEligibilityApiQuery,
} = bronzeGroupTrackingAPI;
