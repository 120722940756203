const content: { [key: string]: any } = {
  pageTitle: "Bronze Group Tracking",
  overview: "Overview",
  tracking: "Tracking",
  graduated: "Graduated",
  graduatedIn: "Window Ended in {graduationDate}",
  search: "Search",
  filter: "Filter",
  status: "Status",
  graduationPeriod: "Window End Period",
  headingDesc: "These metrics display month-over-month changes in your Bronze Group Tracking.",
  overviewTabContent: {
    closeToBronze: "Who's Close to Earning Bronze",
    closeToBronzeTitle: "Close to Bronze",
    closeToBronzeDesc:
      "ABOs who have 2 contributing legs and are at least 50% toward their Performance Bonus requirement.",
    newToBronze: "Who's New to Bronze",
    newToBronzeTitle: "New to Bronze",
    newToBronzeDesc:
      "ABOs who earned their first Bronze Foundation or Bronze Builder incentive in the current or previous month.",
    allTrackingTitle: "All Tracking",
    allTrackingDesc:
      "A list of ABOs in your group that are making progress toward earning Bronze or have started their Bronze eligibility window.",
    graduatedTitle: "Graduated",
    graduatedDesc: "ABOs in your group who have completed Bronze and are working toward other incentives.",
    withinBronzeEligibilityWindow: "Within Bronze Eligibility Window",
    RecentlyGraduatedBronze: "Recently Graduated Bronze",
    closeToFinishing: "Who's Close to Finishing Bronze",
    closeToFinishingDesc:
      "ABOs in your group who have earned the Bronze Incentive at least three times and are now in their final six-month window to qualify for the Bronze Incentive.",
  },
  informationDrawerContent: {
    informationDrawerHeader: "What does this mean?",
    closeToBronzeDrawerDesc:
      "ABOs who have made significant progress toward their leg and Performance Bonus requirements but have not achieved Bronze in the current month. <br><br>For Bronze Foundation, that means at least <1>{bfPerfBonus}</1>% Performance Bonus and at least <2>{bfLegs}</2> legs tracking for <3>{bfAchieved}</3>%. For Bronze Builder, that means at least <4>{bbPerfBonus}</4>% Performance Bonus and at least <5>{bbLegs}</5> legs who have tracking for <6>{bbAchieved}</6>%.",
    newToBronzeDrawerDesc:
      "ABOs who have achieved Bronze Foundation or Bronze Builder for the first time in the current or previous month.",
    graduatedDrawerDesc:
      "ABOs who have achieved <1>{monthsAchieved}</1> months or have completed their eligibility window in the current or previous Performance Year, Continue coaching these ABOs to help them achieve Platinum.",
    trackingBronzeDrawerTitle: "Tracking Bronze",
    trackingBronzeDrawerDesc:
      "ABOs who are in their Bronze eligibility period will always be displayed. <br><br>ABOs who have not started their eligibility window will display if they have a <1>{bfCurrentLevelPercent}</1>% current level and have one <2>{bfLegPercent}</2>% leg for Bronze Foundation or a <3>{bbCurrentLevelPercent}</3>% current level and have one <4>{bbLegPercent}</4>% leg for Bronze Builder.",
    preWindowDrawerTitle: "Pre-Window",
    preWindowDrawerDesc:
      "ABOs meeting the Tracking for Bronze criteria who have not yet earned their first payment for the Bronze Foundation/Bronze Builder incentive.",
    inWindowDrawerTitle: "In-Window",
    inWindowDrawerDesc:
      "ABOs who earned their first month for Bronze Foundation or Bronze Builder, have achieved less than the <1>{monthMaximum}</1>-month maximum, and are still in their eligibility window.",
    restartedDrawerTitle: "Restarted",
    restartedDrawerDesc:
      "ABOs who's Bronze eligibility has restarted. They can earn an additional <1>{additionalPayments}</1> payments within a new <2>{newMonthWindow}</2> month window. Additional Tracking or Graduated requirements must be met for eligible ABOs to be included.",
    requirementsDrawerTitle: "Requirements",
    requirementsDrawerDescOne:
      "The status of each Bronze requirement is displayed visually using a row of checkmark bubbles.",
    requirementsDrawerDescTwo:
      " Each requirement icon represents a specific requirement.  Expand an ABO’s profile by selecting them from the Tracking Menu to see order of requirements and additional details for each requirement.",
  },
  paymentsReceived:
    "<1>{currentPayments}</1>/<2>{totalPayments}</2> payments received (<3>{monthsLeft}</3> months left to earn)",
  viewResults: "View Results",
  cancel: "Cancel",
  inWindow: "In-Window",
  preWindow: "Pre-Window",
  new: "New",
  achieved: "Achieved",
  close: "Close",
  inProgress: "In-Progress",
  bronzeBuilder: "Bronze Builder",
  bronzeFoundation: "Bronze Foundation",
  restarted: "Restarted",
  closeToFinishingBronze: "Close to Finishing Bronze",
  noDownlinesCriteria: "You do not have any downlines that meet these criteria.",
  name: "Name",
  tags: "Tags",
  progress: "Progress",
  eligibility: "Eligibility",
  incentive: "Incentive",
  requirements: "Requirements",
  ppv: "PPV",
  ppvShort: "PPV",
  papv: "PAPV",
  papvShort: "PAPV",
  performanceBonus: "Performance Bonus",
  legsShort: "{numLegs} Legs",
  vcsLegsShort: "{numLegs} VCS Legs",
  threeLegs6: "3 Legs at 6%",
  threeLegs3: "3 Legs at 3%",
  vcsPv: "VCS PV",
  twoLegs6: "2 Legs at 6% + VCS PV",
  vcs: "VCS%",
  vcsTitle: "VCS",
  twoLegs3: "2 Legs at 3% + VCS PV",
  pcpv: "PCPV",
  viewInLOS: "View in LOS",
  requirementsMetRange: "{reqsMet} of {reqsRequired} Requirements Met",
  requirementsRange: "{reqsMet} of {reqsRequired}",
  requirementsMet: " Requirements Met",
  phone: "Phone",
  email: "Email",
  sponsor: "Sponsor",
  monthOverMonth: "MoM",
  legs: "<1>{legs}</1> Legs",
  performanceBonusPercent: "<1>{performanceBonusPercent}</1>%",
  viewAll: "View All",
  noDownlinesTracking:
    "You have no downlines currently in their Bronze Eligibility window or tracking for Bronze Incentives.",
};

export default content;
