import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { LeafModal } from "../../../common/leaf/LeafModal/LeafModal";
import {
  ExportButtonPayload,
  ExportDetailsPayload,
} from "../ServerSideExportCommon/ServerSideExportMenu/ServerSideExportMenu";
import ServerSideExportDownload from "../ServerSideExportCommon/ServerSideExportDownload/ServerSideExportDownload";
import { storedLocale } from "../../impersonation/util";
import { useAppSelector } from "../../../store";
import { CURRENT_VIEW } from "../../../common/constants/export-constants";
import { useCurrentViewExportV2Mutation, useGetExportListV2Query } from "../../../services/ExportV2/exportV2API";
import { CurrentViewExportReqBody, ExportDetails } from "../../../services/ExportV2/exportV2API.types";
import {
  ExportDetailEvent,
  dispatchNotifyInProgressEvent,
  dispatchOpenExportV2ModalEvent,
  dispatchRequestExportDetailsEvent,
} from "../ServerSideExportCommon/ServerSideExportUtil/ServerSideExportEvents";
import "./ReportExportModal.scss";
import defaultContent from "../../../content/serverSideExport";
import {
  exportDeleteArTrack,
  exportDownloadArTrack,
  exportAnotherMenuArTrack,
} from "../../../common/tealium/Export/ServerSideExportTrack";
import { updateInProgressIndicator } from "../../../common/util/exportUtil";

type Props = {
  moduleId: string;
  open: boolean;
  onCloseCallbackFn: Function;
};

export const ReportExportModal = ({ moduleId, open, onCloseCallbackFn }: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const locale = storedLocale();

  const boot = useAppSelector(({ boot }) => boot);
  const { user } = boot;
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { profile } = useAppSelector((store) => store?.boot?.configuration);

  const [currentViewExport] = useCurrentViewExportV2Mutation();

  const { isFetching, data: exportListData, refetch } = useGetExportListV2Query({ moduleId });
  const [isActiveExportButton, setActiveExportButton] = useState(false);

  // Used to display or hide the export in-progress icon
  updateInProgressIndicator(exportListData?.Exports);

  // refetch the export list when change business country or open modal
  useEffect(() => {
    refetch();
  }, [user, open, refetch]);

  const getKPIsContent = useCallback(
    (kpis: string[]) => {
      const exportFields: Record<string, string> = t("exportFields", {
        returnObjects: true,
        defaultValue: defaultContent.exportFields,
      });

      return kpis.map((id) => ({
        id,
        title: exportFields[id] || "not available",
      }));
    },
    [t],
  );

  const getExportPayload = useCallback(
    (payload: ExportButtonPayload) => {
      const { currentView, reportAffAbo } = payload;

      const { address, addressLinesDivider } = profile?.format || {};

      const isoCountryCode = boot?.user?.isoCountryCode || "";

      const textOverride = t("textOverride", { returnObjects: true, defaultValue: defaultContent.textOverride });
      const fields = getKPIsContent(payload.currentView.selectedKPIs);

      const exportBodyBased = {
        moduleId,
        reportAffAbo,
        period: selectedPeriod,
        locale,
        textOverride,
        fields,
        countryCode: isoCountryCode,
        format: {
          address: {
            pattern: address,
            addressLineDivider: addressLinesDivider,
          },
        },
      };
      return {
        ...exportBodyBased,
        affAbos: currentView.currentViewList,
        filteredByKPIS: false,
      };
    },
    [getKPIsContent, moduleId, profile, boot, selectedPeriod, locale, t],
  );

  const onExportAnotherClick = (showExports: boolean) => {
    // 1. click export
    exportAnotherMenuArTrack(moduleId, showExports);
    dispatchRequestExportDetailsEvent(moduleId);
  };

  const triggerExportRequest = useCallback(
    (payload: ExportButtonPayload) => {
      // 3. receive export event
      const exportPayload = getExportPayload(payload);
      // trigger actual export
      currentViewExport(exportPayload as CurrentViewExportReqBody);
      // dismiss modal
      dispatchOpenExportV2ModalEvent(moduleId, false);
      // send notification
      dispatchNotifyInProgressEvent();
    },
    [currentViewExport, getExportPayload, moduleId],
  );

  // setup event listener to listen to "ExportDetails" event from accWcLosHeader (Lit component)
  useEffect(() => {
    const receivedExportDetails = (event: CustomEventInit) => {
      // 2. receive export event
      const exportDetails: ExportDetailsPayload = event.detail;
      triggerExportRequest({
        ...exportDetails,
        selectedId: CURRENT_VIEW,
      });
    };

    window.addEventListener(ExportDetailEvent, receivedExportDetails);
    return () => {
      window.removeEventListener(ExportDetailEvent, receivedExportDetails);
    };
  }, [triggerExportRequest]);

  const losArDataCount = (event: CustomEventInit) => {
    setActiveExportButton(event?.detail?.count > 0);
  };

  useEffect(() => {
    window.addEventListener("acc-wc-view-los-ar-count", losArDataCount);
    return () => {
      window.removeEventListener("acc-wc-view-los-ar-count", losArDataCount);
    };
  }, []);

  const downloadButtonOnClick = (_exportDetails: ExportDetails, fileFormat: string, moduleId: string) => {
    exportDownloadArTrack(fileFormat, moduleId);
  };
  const deleteButtonOnClick = (exportDetails: ExportDetails, moduleId: string) => {
    const { ExportStatus } = exportDetails;
    exportDeleteArTrack(ExportStatus, moduleId);
  };

  return (
    <div className="report-export-modal">
      <LeafModal open={open} onCloseCallbackFn={onCloseCallbackFn} id={"report-export-modal"}>
        <ServerSideExportDownload
          title={`${t("availableExportModal.header", defaultContent.availableExportModal.header)}`}
          subTitle={`${t("availableExportModal.subheader", defaultContent.availableExportModal.subheader)}`}
          exportTypeContent={{
            fullMap: `${t(
              "exportTypeModal.menu.los.selectedKPIs",
              defaultContent.exportTypeModal.menu.los.selectedKPIs,
            )}`,
            fullMapAllKPIs: `${t("exportTypeModal.menu.los.all", defaultContent.exportTypeModal.menu.los.all)}`,
            currentView: `${t(
              "exportTypeModal.menu.los.currentView",
              defaultContent.exportTypeModal.menu.los.currentView,
            )}`,
          }}
          exports={exportListData?.Exports}
          downloadButtonOnClick={downloadButtonOnClick}
          deleteButtonOnClick={deleteButtonOnClick}
          show={true}
          showExportAnotherButton={true}
          exportAnotherButton={{
            exportText: `${t("exportTypeModal.exportButton", defaultContent.exportTypeModal.exportButton)}`,
            exportAnotherText: `${t(
              "availableExportModal.exportAnotherButton",
              defaultContent.availableExportModal.exportAnotherButton,
            )}`,
            active: isActiveExportButton,
            onClick: onExportAnotherClick,
          }}
          moduleId={moduleId}
          isFetching={isFetching}
        />
      </LeafModal>
    </div>
  );
};
