import {
  PPV,
  PERFORMANCE_BONUS,
  THREE_LEGS_6_PERCENT,
  VCS_PV,
  TWO_LEGS_6_PERCENT_WITH_VCS,
  VCS_PERCENT,
  PCPV,
  THREE_LEGS_3_PERCENT,
} from "../../../common/constants/requirements-constants";
import { AboBronzeEligibility, AboTileData } from "../../../services/BronzeGroupTracking/bronzeGroupTracking.types";
import { FilterOption } from "../filters";

export enum BRONZE_GROUP_FILTERS {
  TAGS = "tags",
  REQUIREMENTS = "requirements",
  ELIGIBILITY = "eligibility",
  INCENTIVE = "incentive",
}

export enum FILTER_METHOD {
  OR,
  AND,
}

export interface FilterBTGOption extends FilterOption {
  dataKey: string | Record<string, string>;
  group: BRONZE_GROUP_FILTERS;
  filterMethod?: FILTER_METHOD;
}

export type FilterItem = {
  id: string;
  text: string;
  checked: boolean;
  enable: boolean;
  quickFilter: boolean;
  value: boolean | number | string;
  group: string;
  dataKey?: string | RequirementType;
};

type BGTFilterConfig = {
  enable: boolean;
  group: string;
  id: string;
};

export enum BRONZE_TAGS {
  CLOSE = "close",
  NEW = "new",
  ACHIEVED = "achieved",
  RESTARTED = "restarted",
  CLOSE_TO_FINISHING_BRONZE = "closeToFinishingBronze",
  BRONZE_FOUNDATION = "bronzeFoundation",
  BRONZE_BUILDER = "bronzeBuilder",
  IN_WINDOW = "inWindow",
  PRE_WINDOW = "preWindow",
}

export enum BRONZE_DATA_KEYS {
  CLOSE_NEW = "closeNewTag",
  REQ_TRACKING = "reqTrackingTag",
  RESTARTED = "restarted",
  ELIGIBILITY = "eligibilityTag",
  INCENTIVE = "incentiveTag",
  CLOSE_TO_FINISHING_BRONZE = "closeToGraduating",
}

export enum BRONZE_IDS {
  TAGS_CLOSE = "tags-close",
  TAGS_NEW = "tags-new",
  TAGS_ACHIEVED = "tags-achieved",
  TAGS_RESTARTED = "tags-restarted",
  TAGS_CLOSE_TO_FINISHING_BRONZE = "tags-closeToFinishingBronze",
  REQS_PPV = "requirements-ppv",
  REQS_PCPV = "requirements-pcpv",
  REQS_PERFORMANCE_BONUS = "requirements-performanceBonus",
  REQS_THREE_LEGS_6 = "requirements-threeLegs6",
  REQS_THREE_LEGS_3 = "requirements-threeLegs3",
  REQ_VCS_PV = "requirements-vcsPv",
  REQ_TWO_LEGS_6 = "requirements-twoLegs6",
  REQ_VCS = "requirements-vcs",
  ELIGIBILITY_PRE_WINDOW = "eligibility-preWindow",
  ELIGIBILITY_IN_WINDOW = "eligibility-inWindow",
  INCENTIVE_BRONZE_FOUNDATION = "incentive-bronzeFoundation",
  INCENTIVE_BRONZE_BUILDER = "incentive-bronzeBuilder",
}

export const initialTrackingFilterGroup = [
  {
    group: BRONZE_GROUP_FILTERS.TAGS,
    queryParamValue: void 0,
    isMultipleOption: false,
    filterMethod: FILTER_METHOD.OR,
    disabled: false,
    options: [
      {
        id: BRONZE_IDS.TAGS_CLOSE,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.CLOSE,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.CLOSE_NEW,
      },
      {
        id: BRONZE_IDS.TAGS_NEW,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.NEW,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.CLOSE_NEW,
      },
      {
        id: BRONZE_IDS.TAGS_ACHIEVED,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.ACHIEVED,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.REQ_TRACKING,
      },
      {
        id: BRONZE_IDS.TAGS_RESTARTED,
        checked: false,
        quickFilter: false,
        value: true,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.RESTARTED,
      },
      {
        id: BRONZE_IDS.TAGS_CLOSE_TO_FINISHING_BRONZE,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.CLOSE_TO_FINISHING_BRONZE,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.CLOSE_TO_FINISHING_BRONZE,
      },
    ],
  },
  {
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    queryParamValue: void 0,
    isMultipleOption: true,
    filterMethod: FILTER_METHOD.OR,
    disabled: false,
    options: [
      {
        id: BRONZE_IDS.REQS_PPV,
        checked: false,
        quickFilter: false,
        value: "PPV",
        enable: true,
        dataKey: PPV,
      },
      {
        id: BRONZE_IDS.REQS_PCPV,
        checked: false,
        quickFilter: false,
        value: "PCPV",
        enable: true,
        dataKey: PCPV,
      },
      {
        id: BRONZE_IDS.REQS_PERFORMANCE_BONUS,
        checked: false,
        quickFilter: false,
        value: "Performance Bonus",
        enable: true,
        dataKey: PERFORMANCE_BONUS,
      },
      {
        id: BRONZE_IDS.REQS_THREE_LEGS_6,
        checked: false,
        quickFilter: false,
        value: "3 Legs at 6%",
        enable: true,
        dataKey: THREE_LEGS_6_PERCENT,
      },
      {
        id: BRONZE_IDS.REQS_THREE_LEGS_3,
        checked: false,
        quickFilter: false,
        value: "3 Legs at 3%",
        enable: true,
        dataKey: THREE_LEGS_3_PERCENT,
      },
      {
        id: BRONZE_IDS.REQ_VCS_PV,
        checked: false,
        quickFilter: false,
        value: "VCS PV",
        enable: true,
        dataKey: VCS_PV,
      },
      {
        id: BRONZE_IDS.REQ_TWO_LEGS_6,
        checked: false,
        quickFilter: false,
        value: "2 Legs at 6% + VCS PV",
        enable: true,
        dataKey: TWO_LEGS_6_PERCENT_WITH_VCS,
      },
      {
        id: BRONZE_IDS.REQ_VCS,
        checked: false,
        quickFilter: false,
        value: "VCS%",
        enable: true,
        dataKey: VCS_PERCENT,
      },
    ],
  },
  {
    group: BRONZE_GROUP_FILTERS.ELIGIBILITY,
    queryParamValue: void 0,
    isMultipleOption: false,
    filterMethod: FILTER_METHOD.OR,
    disabled: false,
    options: [
      {
        id: BRONZE_IDS.ELIGIBILITY_PRE_WINDOW,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.PRE_WINDOW,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.ELIGIBILITY,
      },
      {
        id: BRONZE_IDS.ELIGIBILITY_IN_WINDOW,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.IN_WINDOW,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.ELIGIBILITY,
      },
    ],
  },
  {
    group: BRONZE_GROUP_FILTERS.INCENTIVE,
    queryParamValue: void 0,
    isMultipleOption: false,
    filterMethod: FILTER_METHOD.OR,
    disabled: false,
    options: [
      {
        id: BRONZE_IDS.INCENTIVE_BRONZE_FOUNDATION,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.BRONZE_FOUNDATION,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.INCENTIVE,
      },
      {
        id: BRONZE_IDS.INCENTIVE_BRONZE_BUILDER,
        checked: false,
        quickFilter: false,
        value: BRONZE_TAGS.BRONZE_BUILDER,
        enable: true,
        dataKey: BRONZE_DATA_KEYS.INCENTIVE,
      },
    ],
  },
];

const defaultTrackingFilterOptions: BGTFilterConfig[] = [
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.TAGS,
    id: BRONZE_IDS.TAGS_CLOSE,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.TAGS,
    id: BRONZE_IDS.TAGS_CLOSE_TO_FINISHING_BRONZE,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.TAGS,
    id: BRONZE_IDS.TAGS_NEW,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.TAGS,
    id: BRONZE_IDS.TAGS_ACHIEVED,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.TAGS,
    id: BRONZE_IDS.TAGS_RESTARTED,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQ_VCS_PV,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQS_PPV,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQS_PCPV,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQS_PERFORMANCE_BONUS,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQS_THREE_LEGS_6,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQS_THREE_LEGS_3,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQ_TWO_LEGS_6,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.REQUIREMENTS,
    id: BRONZE_IDS.REQ_VCS,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.ELIGIBILITY,
    id: BRONZE_IDS.ELIGIBILITY_PRE_WINDOW,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.ELIGIBILITY,
    id: BRONZE_IDS.ELIGIBILITY_IN_WINDOW,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.INCENTIVE,
    id: BRONZE_IDS.INCENTIVE_BRONZE_FOUNDATION,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.INCENTIVE,
    id: BRONZE_IDS.INCENTIVE_BRONZE_BUILDER,
  },
];

const defaultGraduatedFilterOptions: BGTFilterConfig[] = [
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.INCENTIVE,
    id: BRONZE_IDS.INCENTIVE_BRONZE_FOUNDATION,
  },
  {
    enable: true,
    group: BRONZE_GROUP_FILTERS.INCENTIVE,
    id: BRONZE_IDS.INCENTIVE_BRONZE_BUILDER,
  },
];

export const findSearchResults = (searchKeyword: string, data: AboTileData[]) => {
  return searchKeyword && searchKeyword.length > 0
    ? data.filter((tile) => {
        return (
          tile.aboName.toLowerCase().includes(searchKeyword.toLowerCase()) ||
          tile.aboNumber.toString().includes(searchKeyword) ||
          tile.aboLocalName.toLowerCase().includes(searchKeyword.toLowerCase())
        );
      })
    : data;
};

type RequirementType = {
  requirement: string;
  requirementType: string;
};

const hasSelectedRequirementMet = (abo: AboTileData, req: RequirementType) => {
  const requirement = abo.requirements.find(
    ({ requirement, requirementType }) => req.requirement === requirement && req.requirementType === requirementType,
  );
  const { met = false } = requirement || {};
  return met;
};

/**
 * Filter data list based on the selected filters
 */
export const filterByTags = (
  selectedOptions: FilterBTGOption[],
  aboTileData: AboTileData[],
  bronzeEligibilityData?: AboBronzeEligibility[],
) => {
  const groupedOptions = selectedOptions.reduce<Record<BRONZE_GROUP_FILTERS, FilterBTGOption[]>>((acc, option) => {
    if (!acc[option.group]) acc[option.group] = [];
    acc[option.group].push(option);
    return acc;
  }, {} as Record<BRONZE_GROUP_FILTERS, FilterBTGOption[]>);

  return aboTileData.filter((tile) => {
    return Object.entries(groupedOptions).every(([group, options]) => {
      switch (group) {
        case BRONZE_GROUP_FILTERS.REQUIREMENTS: {
          const reqs = options.map((opt) => opt.dataKey) as RequirementType[];
          const filterMethod = options[0].filterMethod ?? FILTER_METHOD.OR;

          return filterMethod === FILTER_METHOD.OR
            ? reqs.some((req) => hasSelectedRequirementMet(tile, req))
            : reqs.every((req) => hasSelectedRequirementMet(tile, req));
        }

        case BRONZE_GROUP_FILTERS.TAGS: {
          return options.some((option) => {
            if (
              option.dataKey === BRONZE_DATA_KEYS.CLOSE_TO_FINISHING_BRONZE &&
              option.value === BRONZE_TAGS.CLOSE_TO_FINISHING_BRONZE &&
              bronzeEligibilityData
            ) {
              const tileAffAbo = `${tile.affNumber}-${tile.aboNumber}`;

              const eligibleAffAbos = bronzeEligibilityData
                .filter((entry) => {
                  const { bronzeFoundation: f, bronzeBuilder: b, affAbo } = entry;
                  const tag = tile.incentiveTag;

                  if (affAbo !== tileAffAbo || !tag) return false;

                  if (tag === "bronzeFoundation") {
                    return (
                      f?.firstTime?.closeToGraduating === true ||
                      f?.restartOne?.closeToGraduating === true ||
                      f?.restartTwo?.closeToGraduating === true
                    );
                  }

                  if (tag === "bronzeBuilder") {
                    return (
                      b?.firstTime?.closeToGraduating === true ||
                      b?.restartOne?.closeToGraduating === true ||
                      b?.restartTwo?.closeToGraduating === true
                    );
                  }

                  return false;
                })
                .map((entry) => entry.affAbo);
              return eligibleAffAbos.includes(tileAffAbo);
            }

            return tile[option.dataKey as string] === option.value;
          });
        }

        default: {
          return options.some((option) => tile[option.dataKey as string] === option.value);
        }
      }
    });
  });
};

/**
 * Requirement filters and "Restarted" filter currently set by API. The rest will display by their default.
 * Filters can still be set by config BUT consider using the coreplusIncentives API if more filters are needed to
 * be set by ABO/Market data.
 *
 * @param filterConfig BGTFilterConfig[]
 * @param filterMenu FilterOption[]
 * @param identifier "tracking" | "graduated"
 */
export const getUpdatedBGTMenuConfig = (
  filterConfig: BGTFilterConfig[],
  filterMenu: FilterOption[],
  identifier: "tracking" | "graduated",
) => {
  if (!filterConfig || filterConfig.length <= 0) {
    // filter config option not found. Setting filter options to default
    if (identifier === "tracking") filterConfig = defaultTrackingFilterOptions;
    if (identifier === "graduated") filterConfig = defaultGraduatedFilterOptions;
  }
  return filterMenu.map((menu) => {
    const config = filterConfig.find((config) => config.id === menu.id) || null;
    return {
      ...menu,
      ...config,
      enable: config !== null,
    };
  });
};
