import { useTranslation } from "react-i18next";
import { QUALIFICATION_STATUS } from "../../../../../common/enums/incentive-enums";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import defaultContent from "../../../../../content/foundersPlatinumProgressTile";
import defaultBadgeLegendContent from "../../../../../content/badgeLegend";
import { BADGE_SIZE, BADGE_TYPE, IconShield } from "../../../../../common/icons/IconShield/IconShield";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import { getBadgeType } from "../GpvRequirementTile/GpvRequirementTile";
import { SpMonthsRequirement } from "../../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";

type Props = {
  spRequirementTileData: SpMonthsRequirement;
};

export const SpMonthsRequirementTile = ({ spRequirementTileData }: Props) => {
  const { MonthsRequired, MonthsAchieved, MonthsNeeded, MonthsQualificationStatus } = spRequirementTileData;
  const { t } = useTranslation(["foundersPlatinumProgressTile", "badgeLegend"]);
  const title = t("tooltips.spMonths.sectionTitle", defaultContent["tooltips"]["spMonths"]["sectionTitle"]).toString();
  const spQualStatus = getBadgeType(MonthsQualificationStatus);
  const displayLeafBorderColor = MonthsQualificationStatus === QUALIFICATION_STATUS.QUALIFIED;
  const spNeeded = t("tooltips.gpvMonths.spNeeded", defaultContent.tooltips.gpvMonths.spNeeded, {
    monthsNeeded: MonthsNeeded,
  }).toString();
  const notAvailableLabel = t("notAvailableLabel", defaultContent.notAvailableLabel).toString();
  const neededSectionText =
    MonthsQualificationStatus === QUALIFICATION_STATUS.UN_AVAILABLE ? notAvailableLabel : spNeeded;
  const neededSectionClass =
    neededSectionText === spNeeded && MonthsNeeded > 0 ? "FppReqTile__open-text" : "FppReqTile__closed-text";

  const spQualStatusBadge =
    spQualStatus === BADGE_TYPE.OPEN
      ? t("badgeLegend.potential", defaultBadgeLegendContent[BADGE_TYPE.POTENTIAL])
      : t(`badgeLegend.${spQualStatus}`, defaultBadgeLegendContent[spQualStatus]);

  const spMonthsQual = t("tooltips.gpvMonths.spMonthsQual", defaultContent.tooltips.gpvMonths.spMonthsQual, {
    monthsRequired: MonthsRequired,
  });

  const fppReqShieldAriaLabel = `${spQualStatusBadge} ${spMonthsQual}`;

  return (
    <LeafTile borderColor={displayLeafBorderColor ? "#107F47" : ""}>
      <div className="FppReqTile">
        <div className="FppReqTile__top-section">
          <div className="FppReqTile__title">{title}</div>
          <div className="FppReqTile__shield-item" role="img" aria-label={fppReqShieldAriaLabel}>
            <IconShield size={BADGE_SIZE.LARGE} type={spQualStatus} />
            <div>{spMonthsQual}</div>
          </div>
          <div className="FppReqTile__needed-section">
            <div className={neededSectionClass}>{neededSectionText}</div>
          </div>
        </div>

        <LeafTileDivider />

        <div className="FppReqTile__bottom-section">
          <div className="FppReqTile__current-title">
            {t("currentlyHaveLabel", defaultContent["currentlyHaveLabel"]).toString() + ":"}
          </div>
          <div className="FppReqTile__current-requirements">
            {t("tooltips.spMonths.currentSection", defaultContent.tooltips.spMonths.currentSection, {
              spMonthsAchieved: MonthsAchieved,
            })}
          </div>
        </div>
      </div>
    </LeafTile>
  );
};
