export interface FilterOption {
  id: string;
  checked: boolean;
  quickFilter: boolean;
  value: boolean | string | number;
  enable: boolean;
  hideByPeriod?: boolean;
  currentMonthOnly?: boolean;
}

type FilterProp = "hideByPeriod" | "currentMonthOnly";

type FilterConfig = Pick<FilterOption, "id" | "quickFilter" | "hideByPeriod">;

export interface FilterGroup {
  group: string;
  queryParamValue?: string;
  isMultipleOption: boolean;
  options: FilterOption[];
  disabled?: boolean;
  divider?: string;
}

export interface FilterState {
  showFilterMenu: boolean;
  reset: boolean;
  filterGroups: FilterGroup[];
  filterMenu: FilterOption[];
  quickFilterMenu: FilterOption[];
  searchKeyword: string;
  filterCount: number;
  filterLastUpdatedAt: number;
}

export const getFilterQueryParams = <T>(filterGroups: FilterGroup[]): T => {
  const checkedFilters = filterGroups.filter(({ queryParamValue }) => queryParamValue !== void 0);

  return checkedFilters.reduce((allFilters, filter) => {
    const { queryParamValue, group } = filter;

    return {
      ...allFilters,
      [group]: queryParamValue,
    };
  }, {}) as T;
};

export const getFilterMenu = (filterGroups: FilterGroup[]): FilterOption[] => {
  return filterGroups.map(({ options }) => options).flat();
};

export const getQuickFilterMenu = (filterMenu: FilterOption[]): FilterOption[] => {
  return filterMenu.filter(({ quickFilter }) => quickFilter === true);
};

const getUpdatedOptions = (filterGroup: FilterGroup, updatedOptions: FilterOption[]) => {
  const { options } = filterGroup;
  return options.map((option) => {
    const newOption = updatedOptions.find((updatedOption) => updatedOption.id === option.id);
    return newOption ? newOption : option;
  });
};

const isNoneChecked = (filterMenu: FilterOption[]): boolean => {
  return filterMenu.every(({ checked }) => checked === false);
};

const getIsAllChecked = (options: FilterOption[]): boolean => {
  return options.every(({ checked }) => checked === true);
};

const getCheckedOptions = (options: FilterOption[]): FilterOption[] => {
  return options.filter(({ checked }) => checked === true);
};

const getCheckedOptionsValue = (options: FilterOption[]) => {
  return options.map(({ value }) => value).flat();
};

const getQueryParamValue = (filterGroup: FilterGroup): string | undefined => {
  const { options, isMultipleOption, divider = "|" } = filterGroup;
  const isAllChecked = getIsAllChecked(options);
  const checkedOptions = getCheckedOptions(options);
  const checkedValues = getCheckedOptionsValue(checkedOptions);

  if ((isAllChecked === true && isMultipleOption === false) || isNoneChecked(options)) {
    return void 0;
  }
  return checkedValues.join(divider);
};

const updateFilterGroupOptions = (
  currentFilterGroups: FilterGroup[],
  updatedOptions: FilterOption[],
): FilterGroup[] => {
  return currentFilterGroups.map((filterGroup) => ({
    ...filterGroup,
    options: getUpdatedOptions(filterGroup, updatedOptions),
  }));
};

const updateFilterGroupQueryParams = (filterGroups: FilterGroup[]): FilterGroup[] => {
  return filterGroups.map((filterGroup) => ({
    ...filterGroup,
    queryParamValue: getQueryParamValue(filterGroup),
  }));
};

const updateFilterGroup = (currentFilterGroups: FilterGroup[], updatedOptions: FilterOption[]): FilterGroup[] => {
  const updatedFilterGroups = updateFilterGroupOptions(currentFilterGroups, updatedOptions);
  return updateFilterGroupQueryParams(updatedFilterGroups);
};

const getFilterCount = (filterMenu: FilterOption[]) => {
  return filterMenu.filter((filter) => filter.checked).length;
};

const syncMenuState = (filterGroups: FilterGroup[]) => {
  const filterMenu = getFilterMenu(filterGroups);
  const quickFilterMenu = getQuickFilterMenu(filterMenu);
  const filterCount = getFilterCount(filterMenu);
  const reset = isNoneChecked(filterMenu);

  return {
    filterMenu,
    quickFilterMenu,
    filterCount,
    reset,
  };
};

export const getUpdatedFilterGroups = (
  currentFilterGroups: FilterGroup[],
  updatedOptions: FilterOption[],
): {
  reset: boolean;
  filterGroups: FilterGroup[];
  filterMenu: FilterOption[];
  quickFilterMenu: FilterOption[];
  filterCount: number;
} => {
  const updatedFilterGroups = updateFilterGroup(currentFilterGroups, updatedOptions);

  return {
    filterGroups: updatedFilterGroups,
    ...syncMenuState(updatedFilterGroups),
  };
};

export const getUpdatedMenuConfig = (filterConfig: FilterConfig[], filterMenu: FilterOption[]) => {
  return filterMenu.map((menu) => {
    const config = filterConfig.find((config) => config.id === menu.id) || null;

    return {
      ...menu,
      ...config,
      enable: config !== null,
      hideByPeriod: config?.hideByPeriod || false,
    };
  });
};

export const updateFilterMenuEnableStatus = (
  filterGroups: FilterGroup[],
  filterMenu: FilterOption[],
  toggleEnable: boolean,
  filterProp: FilterProp = "hideByPeriod",
) => {
  const updatedFilterMenu = filterMenu.map((filter) => {
    const isTargetFilter = filter[filterProp] === true;
    const isDisabled = toggleEnable === false;
    // Reset checked to false when it is the target filter and going to disable
    const updatedCheck = isTargetFilter && isDisabled ? false : filter.checked;

    return {
      ...filter,
      enable: isTargetFilter ? toggleEnable : filter.enable,
      checked: updatedCheck,
    };
  });
  return getUpdatedFilterGroups(filterGroups, updatedFilterMenu);
};
