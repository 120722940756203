import { ReactNode, useState } from "react";
import "./LeafIconButton.scss";

interface LeafIconButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  Icon: ReactNode;
  HoverIcon?: ReactNode;
  disabled?: boolean;
}

export const LeafIconButton = ({ Icon, HoverIcon, disabled = false, ...htmlAttributes }: LeafIconButtonProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onTouchStart={() => setHovered(true)}
      onTouchEnd={() => setHovered(false)}
      disabled={disabled}
      {...htmlAttributes}
      className={`LeafIconButton__button ${htmlAttributes.className ? htmlAttributes.className : ""}`}
    >
      {hovered ? HoverIcon || Icon : Icon}
    </button>
  );
};
