export const OpenExportV2ModalEvent = "OpenExportV2Modal";
export const NotifyInProgressEvent = "NotifyInProgress";
export const RequestExportDetailsEvent = "RequestExportDetails";
export const ExportDetailEvent = "ExportDetails";
export const ExportCountEvent = "ExportCount";
export const ExportInProgressEvent = "ExportInProgress";

export const dispatchOpenExportV2ModalEvent = (moduleId: string, open: boolean) => {
  window.dispatchEvent(
    new CustomEvent(OpenExportV2ModalEvent, {
      bubbles: true,
      composed: true,
      detail: {
        moduleId,
        open,
      },
    }),
  );
};

export const dispatchNotifyInProgressEvent = () => {
  window.dispatchEvent(
    new CustomEvent(NotifyInProgressEvent, {
      bubbles: true,
      composed: true,
    }),
  );
};

export const dispatchExportCountEvent = (exportCount: number, visibleExportRequestCount: number) => {
  document.dispatchEvent(
    new CustomEvent(ExportCountEvent, {
      bubbles: true,
      composed: true,
      detail: {
        exportCount,
        visibleExportRequestCount,
      },
    }),
  );
};

export const dispatchRequestExportDetailsEvent = (moduleId: string) => {
  document.dispatchEvent(
    new CustomEvent(RequestExportDetailsEvent, {
      bubbles: true,
      composed: true,
      detail: {
        moduleId,
      },
    }),
  );
};

export const dispatchExportInProgressEvent = (isInProgress: boolean) => {
  document.dispatchEvent(
    new CustomEvent(ExportInProgressEvent, {
      bubbles: true,
      composed: true,
      detail: {
        isInProgress,
      },
    }),
  );
};
