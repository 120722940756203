const content: { [key: string]: any } = {
  exportFields: {
    aboNo: "ABO Number",
    level: "ABO Level",
    sponsorABONo: "Sponsor ABO Number",
    countryCode: "Country",
    name: "Name",
    registrationDate: "Entry Date",
    phone: "Phone",
    email: "Email",
    address: "Address",
    bonusPercent: "Bonus Percent",
    personalPV: "PPV",
    groupPV: "GPV",
    groupBV: "GBV",
    totalABOCount: "Total ABOs",
    totalNewABOCount: "New ABOs",
    personalOrdersCount: "Personal Order Count",
    groupOrdersCount: "Group Orders Count",
    pointsToNextLevel: "Points to Next level",
    totalCustomerCount: "Total Customers",
    nonVCSPV: "Non VCS PV",
    customerPV: "Customer PV",
    vcsPV: "VCS PV",
    personalVcsPercent: "VCS %",
    cbrStartMonth: "Starting Month",
    cbrEndMonth: "Ending Month",
    cbrPeriodRemaining: "Remaining Months",
    cbrVcsPercent: "Current Month VCS %",
    birthdate: "Birthday",
    sponsorName: "Sponsor Name",
    renewalDate: "Renewal Date",
    isFirstContractYear: "First Contract Year",
    autoRenewal: "Auto Renewal",
    rubyPV: "Ruby PV",
    qualifiedLegs: "Qualified Legs",
    nonBonusableBV: "Non-Bonusable BV",
    annualPPV: "Annual PPV",
  },
  textOverride: {
    headers: {
      company: "Amway",
      bonusPeriod: "Bonus Period",
      disclaimer:
        "CONFIDENTIAL - THIS REPORT CONTAINS AMWAY'S TRADE SECRETS, INCLUDING ITS LOS INFORMATION, CONFIDENTIAL AND PROPRIETARY BUSINESS INFORMATION. THE RECIPIENT/VIEWER AGREES TO MAINTAIN IT IN STRICTEST CONFIDENCE AND TO USE IT ONLY AS PERMITTED BY CONTRACT WITH AMWAY.",
    },
    phoneType: {
      primary: "Primary",
      secondary: "Secondary",
      home: "Home",
      alternate: "Alternate",
      mobile: "Mobile",
      business: "Business",
      businessFax: "Business Fax",
    },
    name: {
      confidentialUser: "Confidential User",
    },
    cbrPeriodRemaining: {
      closingMonth: "Closed",
    },
    autoRenewal: {
      optIn: "Opt In",
      optOut: "Opt Out",
    },
  },
  exportTypeModal: {
    header: "Select the content to export",
    subheader: "Export is available as a XLS/CSV/PDF file",
    menu: {
      los: {
        all: "Entire Line of Sponsorship with all available KPIs",
        selectedKPIs: "Entire Line of Sponsorship with Selected KPIs",
        currentView: "Current View",
      },
    },
    exportButton: "Export",
    viewExportButton: "View Exports",
  },
  availableExportModal: {
    header: "Available Exports For Download",
    subheader: "Export is available as a XLS/CSV/PDF file",
    exportFile: {
      period: "Period:",
      aboDetail: "ABO #{aboNo} | {country}",
      startTime: "Started:",
      exportError: "Export Failed, please retry again.",
      inProgressMessage: "In Progress...",
      doneBoldMessage: "Ready",
      doneExpiresInMessage: "Expires in",
      lessThan: "less than",
      doneHourMessage: "Hour",
      doneHoursMessage: "Hours",
    },
    exportAnotherButton: "Export Another",
    noExportsAvailable: "You currently have no files ready for export.",
    deleteLabel: "Delete export",
    csvLabel: "Download export in CSV format",
    excelLabel: "Download export in Excel format",
    pdfLabel: "Download export in PDF format",
  },
  notifications: {
    inProgressMessageTitle: "Export is in progress",
    inProgressMessageSubTitle: "An on-screen notification will appear when your file is ready to download.",
    successMessageTitle: "Export Complete",
    successMessageSubTitle: "Your export is now ready to download.",
    failedMessageTitle: "Export Failed",
    failedMessageSubTitle: "Your export request has failed",
    downloadButtonText: "Downloads",
  },
  customer: {
    exportSlider: {
      exportTitle: "Export Customer & Orders",
      downloadTitle: "Download Customer & Orders",
    },
    exportBody: {
      exportBodyTitle: "Customer & Orders Export",
      exportBodySubTitle: "Export is available as a CSV file or XLSX",
      exportAllButton: "Export All",
      exportCustomerListButton: "Export My Customer List",
      exportOrderHistoryButton: "Export My Order History",
    },
    downloadBody: {
      title: "Customer & Orders Download",
      subTitle: "Download chosen file type(s)",
    },
    downloadCard: {
      downloadCSV: "Download as CSV",
      downloadXLSX: "Download as XLSX",
    },
    exportStatus: {
      notExported: "Not Exported",
      exported: "Expiring in {expiresIn} hours",
      expiring: "Expiring in less than 1 hour",
      exporting: "Exporting...",
      expired: "Expired",
      failed: "Failed",
      failedNothingExport: "Failed - Nothing to export",
    },
    deleteExport: "Delete Export",
    customerListDownload: {
      exportedTitle: "My Customer List for",
      notExportedTitle: "My Customer List",
      exportButton: {
        initialExport: "Export My Customer List",
        reExport: "Re-Export My Customer List",
        retryExport: "Retry Download",
      },
      customer: "Customer",
    },
    orderHistoryDownload: {
      exportedTitle: "My Order History for",
      notExportedTitle: "My Order History",
      exportButton: {
        initialExport: "Export My Order History",
        reExport: "Re-Export My Order History  ",
        retryExport: "Retry Download",
      },
      order: "Order",
    },
    tnCAgreement: {
      tnCTitle: "I have read & agree to the",
      tnCLink: "Terms & Conditions",
      tnCNote: "Please agree to the terms and conditions to export content.",
    },
    exportNotifyContainer: {
      toastTitle: "Export Complete",
      toastSubTitle: "File is ready for download.",
      toastButtonText: "Downloads",
    },
    tnCSlider: {
      sliderTitle: "Terms & Conditions",
      tnCTitle: "Terms and Conditions",
      tnCDescription:
        "THIS IS THE PROPERTY OF ALTICOR INC. (“AMWAY”). The information contained in these pages includes Customer information, which constitutes the trade secret, confidential information, and proprietary business information of Amway that is developed, compiled, maintained, and owned exclusively by Amway and protected under the applicable Rules of Conduct. By viewing or obtaining this information, you understand that the information is Amway’s property and agree to maintain the information contained herein in the strictest confidence and to take all necessary steps and appropriate measures to safeguard the information and maintain the confidentiality thereof. You agree not to compile, organize, access, create lists of, or otherwise use or disclose the Customer Information contained within except as authorized by Amway, and only as necessary to facilitate your Amway Business and only in accordance with the applicable Rules of Conduct. Use of Customer Information in connection with any other business or activity is strictly prohibited.",
      tnCAcceptBtn: "Accept",
      tnCDeclineBtn: "Decline",
    },
    customerListExport: {
      fields: {
        name: "Name",
        aboNo: "Customer Number",
        businessNature: "Type",
        clNewCustomer: "New Customer",
        clVerifiedCustomer: "Verified Customer",
        clJoinedDate: "Entry Date",
        phone: "Phone",
        email: "Email",
        address: "Address",
        clTotalOrders: "# of Orders",
        clCustomerPV: "Customer PV",
        clLastOrderDate: "Last Order Date",
        renewalDate: "Renewal Date",
      },
      textOverride: {
        headers: {
          company: "Amway",
          bonusPeriod: "Bonus Period",
          disclaimer:
            "CONFIDENTIAL - THIS REPORT CONTAINS AMWAY'S TRADE SECRETS, CONFIDENTIAL AND PROPRIETARY BUSINESS INFORMATION. THE RECIPIENT/VIEWER AGREES TO MAINTAIN IT IN STRICTEST CONFIDENCE AND TO USE IT ONLY AS PERMITTED BY CONTRACT WITH AMWAY.",
        },
        businessNature: {
          3: "Member",
          4: "Customer",
          17: "Guest Customer",
          sharebar: "Sharebar Customer",
        },
        condition: {
          positive: "Yes",
          negative: "No",
        },
      },
    },
    orderHistoryExport: {
      fields: {
        name: "Name",
        aboNo: "Customer Number",
        ohOrderDate: "Order Date",
        ohOrderNumber: "Order Number",
        ohOrderPV: "PV",
        ohOrderBV: "BV",
        ohOrderType: "Order Type",
        ohVcsReason: "VCS Details",
      },
      textOverride: {
        headers: {
          company: "Amway",
          bonusPeriod: "Bonus Period",
          disclaimer:
            "CONFIDENTIAL - THIS REPORT CONTAINS AMWAY'S TRADE SECRETS, CONFIDENTIAL AND PROPRIETARY BUSINESS INFORMATION. THE RECIPIENT/VIEWER AGREES TO MAINTAIN IT IN STRICTEST CONFIDENCE AND TO USE IT ONLY AS PERMITTED BY CONTRACT WITH AMWAY.",
        },
        orderType: {
          nonVCSOrder: "Non-VCS",
          vcsOrder: "VCS",
          return: "Return",
          upliftOrder: "Uplift",
          adjustment: "Adjustment",
          reworks: "Rework",
          rmsAdjust: "RMS Mass Upload Adjustment",
          cvr: "CVR",
          mars: "MARS",
          backDate: "Back Date",
          other: "Other",
          order: "Order",
        },
        vcsReasons: {
          ADRNM: "The order address does not match the registered address of the customer or member",
          ADRNU: "The order address has been registered to someone else",
          CCNAM: "The name on the credit card does not match the name of the customer or member",
          CCNUM: "The credit card number on the order has been registered to someone else ",
          CDNRO: "The customer indicated that they did not request this order",
          CTYID: "The customer/member identity verification failed",
          ERROR: "There was an error calculating VCS eligibility for this order",
          IMPER: "The order was placed by somebody other than the customer/member",
          ITEMQ: "The maximum quantity was exceeded on at least one item in this order ",
          ITMPV: "The maximum PV limit was exceeded on this order",
          OCOAF: "The customer ordered from more than one ABO this month",
          ORDTY: "Orders of this type do not qualify as verified customer sales",
          OVERR: "The VCS flag was manually overridden by Amway",
          PHONE: "The phone number on the order has been registered to someone else ",
          PMTMD: "The payment method used on the order is not eligible as a verified customer sale",
          PMTNU: "The payment method on the order has been used by someone else",
          PPEND: "The payment method used on the order is pending verification.",
          RBLOC: "Orders for this buyer are blocked from qualifying as verified customer sales",
          SATYP: "Sales to this type of account are not verified customer sales",
          VBLOC: "Orders for this Volume IBO are blocked from qualifying as verified customer sales",
          VCUST: "The buyer is not a verified customer/member",
          VSMAT: "The order recipient matches the Volume ABO account on the order",
        },
      },
    },
  },
};

export default content;
