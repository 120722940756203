import { useEffect, useState } from "react";
import { getAbo, getAff, marketCode } from "../impersonation/util";
import {
  BGTEligibilityResponse,
  BronzeGroupTrackingApiResponse,
} from "../../services/BronzeGroupTracking/bronzeGroupTracking.types";
import {
  useGetBronzeGroupTrackingApiQuery,
  useGetBronzeGroupTrackingEligibilityApiQuery,
} from "../../services/BronzeGroupTracking/bronzeGroupTracking";
import { store, useAppSelector } from "../../store";
import { ROUTE_PATHS } from "../../common/enums/routing-enums";
import { lambdaErrorCheck } from "../../common/util/errorHandling";
import { getCurrentPeriod } from "../../common/util/period";
import { returnDefaultBronzeTrackingData } from "./bronzeGroupTrackingUtils";

export const FetchBronzeGroupTrackingData = (period: string, targetAffAbo?: string) => {
  const { removePeriodSelectorFromRoute = [] } = useAppSelector((state) => state.boot.configuration);
  const { user } = useAppSelector((state) => state.boot);
  const useCurrentPeriod = removePeriodSelectorFromRoute.includes(ROUTE_PATHS.BRONZE_GROUP_TRACKING);
  const {
    data: payloadData,
    isFetching,
    isError,
  } = useGetBronzeGroupTrackingApiQuery({
    aff: getAff(),
    abo: getAbo(),
    period: useCurrentPeriod ? getCurrentPeriod() : period,
    targetAffAbo: targetAffAbo || "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [bronzeTrackingData, setData] = useState<BronzeGroupTrackingApiResponse>(returnDefaultBronzeTrackingData());

  useEffect(() => {
    setIsLoading(isFetching);
    setErrorStatus(isError);
    if (payloadData && !isFetching && !isError) {
      setData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.errors);
      setErrorStatus(foundLambdaError);
    }
  }, [isError, payloadData, isFetching]);

  return { isLoading, bronzeTrackingData, errorStatus };
};

export const FetchBronzeEligibilityData = (affAbos: string[], period: string, skipEligibilityQuery: boolean) => {
  const { removePeriodSelectorFromRoute = [] } = useAppSelector((state) => state.boot.configuration);
  const { user } = useAppSelector((state) => state.boot);
  const useCurrentPeriod = removePeriodSelectorFromRoute.includes(ROUTE_PATHS.BRONZE_GROUP_TRACKING);
  const {
    data: payloadData,
    isFetching,
    isError,
  } = useGetBronzeGroupTrackingEligibilityApiQuery(
    {
      affAbos,
      period: useCurrentPeriod ? getCurrentPeriod() : period,
      countryCode: marketCode(),
    },
    {
      skip: skipEligibilityQuery,
    },
  );

  const [isApiLoading, setIsApiLoading] = useState(true);
  const [errorEligibilityStatus, setErrorEligibilityStatus] = useState(false);
  const [bronzeEligibilityData, setData] = useState<BGTEligibilityResponse>();

  useEffect(() => {
    setIsApiLoading(isFetching);
    setErrorEligibilityStatus(isError);
    if (payloadData && !isFetching && !isError) {
      setData(payloadData);
      const foundLambdaError = lambdaErrorCheck(payloadData.Error ?? []);
      setErrorEligibilityStatus(foundLambdaError);
    }
  }, [isError, payloadData, isFetching]);

  return { isApiLoading, bronzeEligibilityData, errorEligibilityStatus };
};
