import { _createGlobalUserID, storedLocale } from "../../components/impersonation/util";
import { BootType } from "../../reducers/boot";
import { storedIsoCountry } from "./config";

declare global {
  interface Window {
    requestIdleCallback(callback: IdleRequestCallback, options?: IdleRequestOptions): number;
  }
}
let __viewQueueInterval: string | number | NodeJS.Timeout | null | undefined = null;
const __viewQueue = [];

interface viewDataObject {
  page_section: string;
  page_category: string;
  page_detail: string;
  page_action: string;
}

interface clickDataObject {
  event_name: string;
  click_section: string;
  click_category: string;
  click_detail: string;
  click_action: string;
}

/**
 * This function loads the correct Tealium script for the page
 */
export const loadTealiumScript = async (state: BootType) => {
  const isoCountryCode = storedIsoCountry() || "US";
  const locale = storedLocale() || "en-US";

  try {
    const config = state.configuration;
    const countries = config.countries;
    const abo = state.user.abo;
    const aff = state.user.aff;
    const partyId = state.user.partyId;
    const siteType = "responsive";
    const userProfile = "abo";

    //disable automated page tracking
    window.utag_cfg_ovrd = { noview: true };
    // set utag user data
    window.utag_data = {
      site_type: siteType,
      site_country: isoCountryCode.toLowerCase(),
      site_language: locale.split("-")[1],
      site_region: countries[isoCountryCode][3],
      site_subRegion: countries[isoCountryCode][0],
      site_currencyCode: countries[isoCountryCode][1],
      site_prefix: "amway",
      site_type_short: "web",
      site_webProperty: "coreplus",
      visitor_customerID: "",
      visitor_imcID: _createGlobalUserID(aff, abo),
      visitor_partyID: partyId,
      visitor_userProfile: userProfile,
      visitor_currentAwardRank: sessionStorage.getItem("tealiumCurrentAwardRank"),
    };

    let src = "//tags.tiqcdn.com/utag/amway/coreplus/dev/utag.js";

    if (config.env === "prod") {
      src = "//tags.tiqcdn.com/utag/amway/coreplus/prod/utag.js";
    }

    (function (a, b, c, d) {
      // @ts-expect-error
      a = src;
      // @ts-expect-error
      b = document;
      // @ts-expect-error
      c = "script";
      // @ts-expect-error
      d = b.createElement(c);
      // @ts-expect-error
      d.src = a;
      // @ts-expect-error
      d.type = "text/java" + c;
      // @ts-expect-error
      d.async = true;
      // @ts-expect-error
      a = b.getElementsByTagName(c)[0];
      // @ts-expect-error
      a.parentNode.insertBefore(d, a);
    })();
  } catch (e) {
    console.warn("tealium utag data not set: ", e);
  }
};

/**
 * Internal method to send to uTag via requestIdleCallback
 * @param {object} data
 * @param {string} utagAction
 */
function __send(
  data:
    | {
        page_section: string;
        page_category: string;
        page_detail: string;
        page_action: string;
        event_name?: undefined;
        click_section?: undefined;
        click_category?: undefined;
        click_detail?: undefined;
        click_action?: undefined;
      }
    | {
        event_name: string;
        click_section: string;
        click_category: string;
        click_detail: string;
        click_action: string;
        page_section?: undefined;
        page_category?: undefined;
        page_detail?: undefined;
        page_action?: undefined;
      },
  utagAction: string,
) {
  const udo = window.utag_data || {};
  if (!window.requestIdleCallback) {
    // fallback scenario with RIC not available
    window.utag[utagAction]({ ...udo, ...data });
  } else {
    requestIdleCallback(
      () => {
        window.utag[utagAction]({ ...udo, ...data });
      },
      {
        timeout: 2000,
      },
    );
  }
}

function isObjectEmpty(obj: { constructor?: any }) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

function isUtagAvailable() {
  return window.utag && !isObjectEmpty(window.utag);
}

function processQueue() {}

function stopUtagCheckInterval() {
  if (__viewQueueInterval) {
    clearInterval(__viewQueueInterval);

    __viewQueueInterval = null;
  }
}

function runUtagCheckInterval() {
  if (__viewQueueInterval) {
    return;
  }

  __viewQueueInterval = setInterval(() => {
    if (isUtagAvailable()) {
      stopUtagCheckInterval();
      processQueue();
    }
  }, 5000);
}

/**
 * Track a view or click with Tealium's uTag
 * @param {string} [utagMethod='view']
 * @param {string} [event_name='']
 * @param {string} [section='']
 * @param {string} [category='']
 * @param {string} [detail='']
 * @param {string} [action='']
 * @export
 */
export const track = (utagMethod = "view", event_name = "", section = "", category = "", detail = "", action = "") => {
  let dataObject: viewDataObject | clickDataObject;

  if (utagMethod === "view") {
    dataObject = {
      page_section: section,
      page_category: category,
      page_detail: detail,
      page_action: action,
    };
  } else {
    dataObject = {
      event_name,
      click_section: section,
      click_category: category,
      click_detail: `${detail}`,
      click_action: `${action}`,
    };
  }

  // remove any key that's empty or null
  const keys = Object.keys(dataObject) as Array<keyof typeof dataObject>;
  keys.forEach((key) => {
    if (dataObject[key] === null || dataObject[key] === "") {
      delete dataObject[key];
    }
  });

  // Always check for the top level definition
  if (isUtagAvailable()) {
    stopUtagCheckInterval();
    processQueue();

    __send(dataObject, utagMethod);
  } else {
    // if tealium hasn't loaded, we just queue everything an try later
    __viewQueue.push({ dataObject, utagAction: utagMethod });

    runUtagCheckInterval();
  }
};
